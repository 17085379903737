import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import "../Style/EssayPage.css"
import "../Style/CardPage.css"

const EnglishCards = ({data}) => (
 <div> 

    <SEO 
    title="English Words Cards"
    />

    <Helmet>

        <meta name="robots" content="noindex" />
        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="Phil Murphy">
        </meta>

        <meta name="og:description" content="Phil Murphy">
        </meta>

        <meta name="description" content="Phil Murphy">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="Phil Murphy">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>


        <div className="CardsPageSettings">

        <div className="CardsPageMenu">
            <Link to="/english-cards-123456789"><div className="CardsPageMenuOptions">1</div></Link>
            <Link to="/english-cards-123456789-2"><div className="CardsPageMenuOptions">2</div></Link>
            <Link to="/english-cards-123456789-y"><div className="CardsPageMenuOptions">y</div></Link>
        </div>


                <div className="SectionPageCards">

                            <div className="PageCardSmallTitle">Sentence</div>
                            <div className="PageCardBigTitle">After hours of atrocious deliberation for some inexplicable reasons, we felt liberated but traumatized</div>

                            <div className="PageCardsGroup">


                                <div className="PageCardContent">

                                    <div className="ContentCardBigTitle">Deliberation</div>
                                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                                    <div className="ContentCardMediumTitle"><b>i.e:</b> long and careful consideration or discussion.</div>
                                    <div className="ContentCardMediumTitle"><b>e.g:</b> After hours of deliberation, the council came to a decision</div>

                                </div>

                                <div className="PageCardContent">

                                    <div className="ContentCardBigTitle">Inexplicable</div>
                                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                                    <div className="ContentCardMediumTitle"><b>i.e:</b> unable to be explained or accounted for.</div>
                                    <div className="ContentCardMediumTitle"><b>e.g:</b> for some inexplicable reason her mind went completely blank</div>

                                </div>

                                <div className="PageCardContent">

                                    <div className="ContentCardBigTitle">Atrocious</div>
                                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                                    <div className="ContentCardMediumTitle"><b>i.e:</b> horrifyingly wicked. // of a very poor quality; extremely bad or unpleasant.</div>
                                    <div className="ContentCardMediumTitle"><b>e.g:</b> he attempted an atrocious imitation of my English accent</div>

                                </div>

                                <div className="PageCardContent">

                                    <div className="ContentCardBigTitle">Liberating</div>
                                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                                    <div className="ContentCardMediumTitle"><b>i.e:</b>  providing a release from a situation which limits freedom of thought or behaviour.</div>
                                    <div className="ContentCardMediumTitle"><b>e.g:</b> the arts can have a liberating effect on people</div>

                                </div>

                                <div className="PageCardContent">

                                    <div className="ContentCardBigTitle">Traumatized</div>
                                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                                    <div className="ContentCardMediumTitle"><b>i.e:</b>  subject to lasting shock as a result of a disturbing experience or physical injury.
</div>
                                    <div className="ContentCardMediumTitle"><b>e.g:</b> the children were traumatized by separation from their families</div>

                                </div>

                        

                            </div>


                </div>



        <hr />


                <div className="SectionPageCards">

                            <div className="PageCardSmallTitle">Sentence</div>
                            <div className="PageCardBigTitle">Work becomes more elusive, but riveting explanation gives hope, although after invading his privacy he nearly died with fatigue</div>

                            <div className="PageCardsGroup">


                                <div className="PageCardContent">

                                    <div className="ContentCardBigTitle">Fatigue</div>
                                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                                    <div className="ContentCardMediumTitle"><b>i.e:</b> extreme tiredness resulting from mental or physical exertion or illness.</div>
                                    <div className="ContentCardMediumTitle"><b>e.g:</b> he was nearly dead with fatigue</div>

                                </div>

                                <div className="PageCardContent">

                                    <div className="ContentCardBigTitle">Riveting</div>
                                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                                    <div className="ContentCardMediumTitle"><b>i.e:</b> having the power to fix the attention</div>
                                    <div className="ContentCardMediumTitle"><b>e.g:</b> a riveting story; a riveting explanation of light waves that fascinated the class</div>

                                </div>

                                <div className="PageCardContent">

                                    <div className="ContentCardBigTitle">Elusive</div>
                                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                                    <div className="ContentCardMediumTitle"><b>i.e:</b>  difficult to find, catch, or achieve.</div>
                                    <div className="ContentCardMediumTitle"><b>e.g:</b> success will become ever more elusive</div>

                                </div>

                                <div className="PageCardContent">

                                    <div className="ContentCardBigTitle">Invading</div>
                                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                                    <div className="ContentCardMediumTitle"><b>i.e:</b>  encroach or intrude on. (violate)</div>
                                    <div className="ContentCardMediumTitle"><b>e.g:</b> he felt his privacy was being invaded</div>

                                </div>

                        

                            </div>


                </div>



                <hr />


<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">She saw the resemblance, but he was flawed and not enough arrogance, so she absorbs information carefully not make any false assumptions</div>

            <div className="PageCardsGroup">


                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Resemblance</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> a way in which two or more things are alike.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> He doesn't look exactly like his father, but there is some resemblance.</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Absorb</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> take in and understand fully (information, ideas, or experience).</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> she absorbed the information in silence</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Assumption</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> a thing that is accepted as true or as certain to happen, without proof.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> they made certain assumptions about the market</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Flawed</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> having a weakness</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> flawed character</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Arrogance</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> overconfidence</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> the arrogance of this man is astounding</div>

                </div>

        

            </div>


</div>




<hr />


<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">We were frightened and divisive but implied that his attitude towards the problem will be tolerated</div>

            <div className="PageCardsGroup">


                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Frightened</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> afraid or anxious</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> a frightened child</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Attitude</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> a settled way of thinking or feeling about something (what's your approach?).</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> he was questioned on his attitude to South Africa</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Tolerate</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> accept or endure</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> how was it that she could tolerate such noise?</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Divisive</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> tending to cause disagreement or hostility between people.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> TLOU 2 is divisive game</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Implying</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> indicate the truth or existence of (something) by suggestion rather than explicit reference.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> Sheldon: I was implying it</div>

                </div>

        

            </div>


</div>




<hr />


<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">Some caveats: a disingenuous worker caused some precedents when she gloated over her boss and others just piled on</div>

            <div className="PageCardsGroup">


                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Piled on</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> to join other people in criticizing something or someone</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> You started it, I just piled on</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Gloat</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> dwell on one's own success or another's misfortune with smugness or malignant pleasure.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> his enemies gloated over his death</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Caveats</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b>  warning or proviso of specific stipulations, conditions, or limitations.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> here are a number of caveats which concern the validity of the assessment results</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Precedent</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> an earlier event or action that is regarded as an example or guide to be considered in subsequent similar circumstances.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> there are substantial precedents for using interactive media in training</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Disingenuous</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> not candid or sincere, typically by pretending that one knows less about something than one really does.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> this journalist was being somewhat disingenuous as well as cynical</div>

                </div>

        

            </div>


</div>




<hr />


<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">After a quick recap on a whim, I don't wanna jinx it, but seems we've successfully anticipated and envisaged our future</div>

            <div className="PageCardsGroup">


                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">On a whim</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> sudden decision</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> because of a sudden decision He quit his job on a whim</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Jinx</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> a person or thing that brings bad luck.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> I don't wanna jinx it</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Anticipate</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> guess or be aware of (what will happen) and take action in order to be prepared.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> they failed to anticipate a full-scale invasion</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Recap</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> a summary of what has been said; a recapitulation.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> a quick recap of the idea and its main advantages</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Envisage</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> contemplate or conceive of as a possibility or a desirable future event.; form a mental picture</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> Nobody can envisage the consequences of total nuclear war</div>

                </div>

        

            </div>


</div>




<hr />


<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">He was feeling smug after encountered one prominent problem and was able to refine it and seems to have that ability innate</div>

            <div className="PageCardsGroup">


                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Smug</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> having or showing an excessive pride in oneself or one's achievements.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> he was feeling smug after his win</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Innate</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> inborn; natural.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> Cruelty and treachery seem innate in the whole family.</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Encountered</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> unexpectedly be faced with or experience (something hostile or difficult).</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> we have encountered one small problem</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Refine</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> make minor changes so as to improve or clarify</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> ease of access to computers has refined analysis and presentation of data</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Prominent</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> important; famous.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> she was a prominent member of the city council</div>

                </div>

        

            </div>


</div>




<hr />


<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">I'm not insulting you for taking consecutively bribes because it's fuzzy but it wasn't your part of our bargain</div>

            <div className="PageCardsGroup">


                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Insult</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> speak to or treat with disrespect or scornful abuse.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> you're insulting the woman I love</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Bribe</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> a sum of money or other inducement offered or given to bribe someone.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> lawmakers were caught accepting bribes to bring in legalized gambling</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Bargain</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> an agreement between two or more people or groups as to what each will do for the other.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> bargains between political parties supporting the government</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Consecutive</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> following each other continuously.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> five consecutive months of serious decline</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Fuzzy</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> unable to think clearly; confused.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> the picture is very fuzzy</div>

                </div>

        

            </div>


</div>



<hr />


<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">I don't want to interrupt, but I feel dubious about your ability to always surpass me. We must distinguish areas where we operate because I want to be exhilarated at least one time</div>

            <div className="PageCardsGroup">


                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Interrupt</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> stop (someone speaking) by saying or doing something.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> Of course …’ Shepherd began, but his son interrupted him</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Dubious</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> hesitating or doubting.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> I was rather dubious about the whole idea</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Surpass</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> be better/greater than.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> he continued to surpass me at all games</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Exhilarating</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b>  make (someone) feel very happy, animated, or elated.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> she was exhilarated by the day's events</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Distinguish</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> recognize or point out a difference.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> we must distinguish between two kinds of holiday</div>

                </div>

        

            </div>


</div>



<hr />


<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">The fused rope comes in handy for our sturdy troops even though we lost in righteousness</div>

            <div className="PageCardsGroup">

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Come in handy</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> turn out to be useful.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> the sort of junk that might come in handy one day</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Righteousness</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> the quality of being morally right or justifiable.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> we had little doubt about the righteousness of our cause</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Fused</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> it's connected or joined to something else</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> Fused wires have been soldered together</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Sturdy</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> strongly and solidly built</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> This order was met with a sturdy refusal to move.</div>

                </div>

        

            </div>


</div>



<hr />


<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">We said kudos to Emmy in desperation but it was imposed by our opponent, yet so relief</div>

            <div className="PageCardsGroup">

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Desperation</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> a state of despair, typically one which results in rash or extreme behaviour.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> she wrote to him in desperation</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Imposed</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> force (an unwelcome decision or ruling) on someone.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> the decision was theirs and was not imposed on them by others</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Kudos</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> praise and honour received for an achievement.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> she was looking for kudos rather than profit</div>

                </div>

                <div className="PageCardContent">

                  <div className="ContentCardBigTitle">Relief</div>
                  <div className="ContentCardSmallTitle">Meaning + Example</div>
                  <div className="ContentCardMediumTitle"><b>i.e:</b> a feeling of reassurance and relaxation following release from anxiety or distress.</div>
                  <div className="ContentCardMediumTitle"><b>e.g:</b> Every word is relief</div>

                </div>

        

            </div>


</div>

<hr />

<div className="SectionPageCards">

            <div className="PageCardSmallTitle">Sentence</div>
            <div className="PageCardBigTitle">This superb show leaves me in a conundrum since they fiercely shoved aside some story arcs, but I digress.</div>

            <div className="PageCardsGroup">

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Conundrum</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> a confusing and difficult problem or question.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> And so, at the end of nearly 5,500 words of review, everything that I’ve written leaves me in a conundrum.</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Superb</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> very good; excellent.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> a superb performance</div>

                </div>

                <div className="PageCardContent">

                    <div className="ContentCardBigTitle">Digress</div>
                    <div className="ContentCardSmallTitle">Meaning + Example</div>
                    <div className="ContentCardMediumTitle"><b>i.e:</b> leave the main subject temporarily in speech or writing.</div>
                    <div className="ContentCardMediumTitle"><b>e.g:</b> but I digress</div>

                </div>

                <div className="PageCardContent">

                  <div className="ContentCardBigTitle">Shoved aside</div>
                  <div className="ContentCardSmallTitle">Meaning + Example</div>
                  <div className="ContentCardMediumTitle"><b>i.e:</b> avoid by turning away.</div>
                  <div className="ContentCardMediumTitle"><b>e.g:</b> Shirley Freedman, the young girl you shoved aside earlier.</div>

                </div>

                <div className="PageCardContent">

                  <div className="ContentCardBigTitle">Fiercely</div>
                  <div className="ContentCardSmallTitle">Meaning + Example</div>
                  <div className="ContentCardMediumTitle"><b>i.e:</b> in a savagely violent or aggressive manner.</div>
                  <div className="ContentCardMediumTitle"><b>e.g:</b> fiercely tried to prove</div>

                </div>

        

            </div>


</div>


        </div>









    







    </div>
)

export default EnglishCards
